import * as React from "react"
import { graphql } from "gatsby";
import { Container } from "react-bootstrap"
import Footer from "../parts/Footer"
import Header from "../parts/Header"
import PublicationList from "../components/publications/PublicationList"
import CustomHelmet from "../components/seo/CustomHelmet";


// markup
const Publications = ({ location, data }) => {
  const canonicalUrl = data.site.siteMetadata.siteUrl + location.pathname

  const { allMarkdownRemark } = data;
  const { group } = allMarkdownRemark;
  const publications = group.reverse();
  return (
    <>
      <CustomHelmet title={"Publications"} canonicalUrl={canonicalUrl} />
      <Header activeKey="/publications" />
      <main className="pt-5" style={{ marginTop: '81px' }}>
        <Container>
          <h1 className="mb-4">Publications</h1>
          <PublicationList publications={publications} />
        </Container>
      </main>
      <Footer />
    </>
  )
}

export const pageQuery = graphql`
{
  allMarkdownRemark(
    filter: {frontmatter: {taxonomy: {eq: "publication"}}}
    sort: {order: DESC, fields: frontmatter___slug}
  ) {
    group(field: frontmatter___year) {
      fieldValue
      nodes {
        frontmatter {
          slug
          year
          authors
          link
          title
        }
        id
      }
    }
  }
  site {
    siteMetadata {
      siteUrl
    }
  }
}

`

export default Publications;
