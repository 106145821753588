import * as React from "react";
import PublicationCard from "../PublicationCard";
import * as styles from './index.module.scss';



// markup
const PublicationList = ({ publications }) =>
    <div className={styles.publicationsWrapper}>
        {
            publications.map(publicationGroup =>
                <div key={publicationGroup.fieldValue} className={styles.publicationGroupWrapper}>
                    <h2 className={styles.year}>{publicationGroup.fieldValue}</h2>
                    {
                        publicationGroup.nodes.map(
                            publication => <PublicationCard
                                key={publication.id}
                                title={publication.frontmatter.title}
                                authors={publication.frontmatter.authors}
                                excerpt={publication.html}
                                url={publication.frontmatter.link}
                                year={publication.frontmatter.year}
                            />
                        )
                    }
                </div>
            )}
    </div>



export default PublicationList;
