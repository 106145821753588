import * as React from "react";
import * as styles from './index.module.scss';

// markup
const PublicationCard = ({ title, authors, excerpt, url }) => {
    return (
        <div className={`bg-white ${styles.cardWrapper}`}>
            <h2 className={styles.title}>{title}</h2>
            <p className="text-primary fw-bold">{authors}</p>
            {excerpt ? <div className="mb-4" dangerouslySetInnerHTML={{__html: excerpt}} /> : ''} 
            <a href={url} target="_blank" rel="noreferrer" className="btn btn-primary rounded-pill text-white px-4">Read the paper</a>
            <div className={styles.indicator} />
        </div>
    )
}

export default PublicationCard;
